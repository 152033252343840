



































































































































import Component, { mixins } from 'vue-class-component'
import { State } from '@/mixins/state'

import {
  SubscriptionApi,
  GetPossibleSubscriptionEndDatesQuery,
  GetQueuedCancellationQuery,
  DeleteQueuedSubscriptionCancellationCommand,
  CancelReasonDto,
  QueuedCancellationDto,
  CancelReasonId
} from '@/api'

import Content from '@/components/Content.vue'
import Toolbar from '@/components/Layout/Toolbar.vue'
import FormInput, { InputField, FormInputInstance } from '@/components/Form/Input.vue'
import FormButton from '@/components/Form/Button.vue'
import { Cancellation, User } from '@/types'
import { Watch } from 'vue-property-decorator'
import { formatDate } from '@/util/formatDate'

interface InputFields {
  cancellationDate: InputField;
  reason: InputField;
  upload: InputField;
  explanation: InputField;
}

@Component({
  components: {
    Content,
    Toolbar,
    FormInput,
    FormButton
  },
  methods: {
    formatDate
  }
})
export default class SubscriptionCancel extends mixins(State) {
  inputFields: InputFields = {
    cancellationDate: {
      name: 'cancellationDate',
      value: '',
      required: true,
      type: 'select',
      options: [],
      placeholder: 'Graag opzeggen per',
      errors: []
    },

    reason: {
      name: 'reason',
      value: '',
      required: true,
      type: 'select',
      placeholder: 'Reden van opzeggen?',
      errors: [],
      options: []
    },

    upload: {
      name: 'upload',
      value: '',
      required: false,
      type: 'file',
      placeholder: 'Upload een verklaring van opzegging',
      errors: [],
      hidden: true,
      customErrorMessage: 'Vergeet niet je bestand te uploaden',
      info: 'Je kunt de volgende bestandstypen uploaden: .jpg, .png, .pdf',
      inputExplanationParagraph: 'Op de getoonde einddatum loopt jouw abonnement af. Je hebt aangegeven al bij de sportlocatie opgezegd te hebben. Is dit per een eerdere einddatum? Upload een bevestiging, dan kijken we of je in aanmerking komt voor onze coulanceregeling*. We sturen je een e-mail met daarin de definitieve einddatum van jouw abonnement.'
    },

    explanation: {
      name: 'explanation',
      value: '',
      required: false,
      type: 'textarea',
      placeholder: 'Eventuele toelichting',
      errors: [],
      hidden: true
    }
  }

  onError = false
  isLoading = true
  queuedCancellationInfo: QueuedCancellationDto|undefined = undefined
  reasons: CancelReasonDto[] = []
  showMedicalReasonNotification = false;

  firstCancellationDate = ''

  get isMedicalReason () {
    return CancelReasonId[this.inputFields.reason.value as keyof typeof CancelReasonId] === CancelReasonId.MedicalReason
  }

  get isLeavingWork () {
    return this.queuedCancellationInfo?.cancelReasonId === CancelReasonId.LeavingWork
  }

  get isMoving () {
    // relocate to another home / rehousing
    return CancelReasonId[this.inputFields.reason.value as keyof typeof CancelReasonId] === CancelReasonId.Moving
  }

  get isOther () {
    return CancelReasonId[this.inputFields.reason.value as keyof typeof CancelReasonId] === CancelReasonId.Other
  }

  get isCancelledAtSportLocation () {
    return CancelReasonId[this.inputFields.reason.value as keyof typeof CancelReasonId] === CancelReasonId.CancelledAtSportLocation
  }

  get currentUser (): User {
    return this.$store.state.currentUser
  }

  get subscription () {
    return this.currentUser.subscription
  }

  get cancellationInfo (): Cancellation {
    return this.$store.state.cancel.cancellation
  }

  get contactSupportReason () {
    if (!this.inputFields || !this.inputFields.reason) {
      return false
    }
    return this.currentUser.subscriptionDetails?.renewalNumber === 0 && (this.isMoving || this.isMedicalReason)
  }

  @Watch('inputFields.reason.value')
  onInputValueChanged () {
    // Hide when contacting customer support, or when reason is not 'Other'.
    this.inputFields.explanation.hidden = this.contactSupportReason || !this.isOther

    if (this.isCancelledAtSportLocation) {
      this.inputFields.explanation.hidden = false
      this.inputFields.upload.hidden = false
      this.inputFields.upload.required = true
    } else if (this.isMedicalReason) {
      this.showMedicalReasonNotification = true
    } else {
      this.inputFields.upload.hidden = true
      this.inputFields.upload.required = false
      this.showMedicalReasonNotification = false
    }
  }

  mounted () {
    this.queuedCancellationInfo = undefined
    this.isLoading = true
    let isLoadingEndDates = true
    let isLoadingCancelReasons = true
    let isLoadingQueueInfo = true

    const cancellationInfo = this.cancellationInfo
    this.inputFields.cancellationDate.value = cancellationInfo.cancellationDate
    this.inputFields.reason.value = cancellationInfo.cancelReasonId
    this.inputFields.explanation.value = cancellationInfo.cancelExplanation || ''
    const api = new SubscriptionApi()

    const endDatesQuery: GetPossibleSubscriptionEndDatesQuery = {
      subscriptionId: this.currentUser.subscription?.subscriptionId || 0
    }
    api.subscriptionGetPossibleSubscriptionEndDates(endDatesQuery)
      .then((data) => {
        const dates = data.data
        // Show error when no end dates are returned.
        if (dates.length === 0) {
          this.onError = true
        }

        this.firstCancellationDate = dates[0]

        dates.forEach(item => {
          if (this.inputFields.cancellationDate.options) {
            this.inputFields.cancellationDate.options.push({
              value: item,
              label: formatDate(item)
            })
          }
        })

        isLoadingEndDates = false
        this.isLoading = isLoadingEndDates || isLoadingCancelReasons || isLoadingQueueInfo
      })
      .catch(() => {
        this.onError = true
      })

    api.subscriptionGetCancelReasons()
      .then((data) => {
        data.data.forEach(item => {
          if (this.inputFields.reason.options) {
            this.reasons.push(item)

            this.inputFields.reason.options.push(
              {
                label: item.reason || '',
                value: (item.cancelReasonId || '').toString()
              }
            )
          }
        })

        isLoadingCancelReasons = false
        this.isLoading = isLoadingEndDates || isLoadingCancelReasons || isLoadingQueueInfo
      })
      .catch(() => {
        this.onError = true
      })

    const queuedQuery: GetQueuedCancellationQuery = {
      subscriptionId: this.currentUser.subscription?.subscriptionId || 0
    }
    api.subscriptionGetQueuedCancellationInfo(queuedQuery)
      .then((data) => {
        this.queuedCancellationInfo = data.data

        isLoadingQueueInfo = false
        this.isLoading = isLoadingEndDates || isLoadingCancelReasons || isLoadingQueueInfo
      })
      .catch((error) => {
        // 404 when no queued cancellation is found.
        isLoadingQueueInfo = false
        this.isLoading = isLoadingEndDates || isLoadingCancelReasons || isLoadingQueueInfo

        // this endpoint has a 'valid' response of notFound (404)
        // so we need to check for this. In other cases e.g. 500 errors
        // we need to show the user an message that something got wrong.
        if (error.response && error.response.status !== 404) {
          // turn off the loader and show an the errormessage.
          this.isLoading = false
          this.onError = true
        }
      })
  }

  public async deleteCancellation () {
    const api = new SubscriptionApi()

    const fields: DeleteQueuedSubscriptionCancellationCommand = {
      subscriptionId: this.currentUser.subscription?.subscriptionId || 0

    }
    await api.subscriptionDeleteSubscriptionCancellation(fields).then(() => {
      this.$router.push({ name: 'subscriptionCancelDeleted' })
    })
      .catch((error) => {
        this.setErrors(error.response.data.errors)
      })
  }

  public async cancelSubscription () {
    this.clearErrors()
    if (!this.validate()) {
      return
    }

    this.$store.commit('cancel/SET_CANCELLATION', {
      cancellationDate: this.inputFields.cancellationDate.value,
      cancelReasonId: this.inputFields.reason.value,
      cancelUploadGuid: this.inputFields.upload.value,
      cancelExplanation: this.inputFields.explanation.hidden === true ? null : this.inputFields.explanation.value
    })
    this.$router.push({ name: 'subscriptionCancelConfirm' })
  }

  public setErrors (errors: []) {
    Object.entries(errors).forEach(([errorKey, errorValue]) => {
      Object.entries(this.inputFields).forEach(([fieldKey, fieldValue]) => {
        if (errorKey.toLowerCase() === fieldKey.toLowerCase()) {
          fieldValue.errors = errorValue
        }
      })
    })
  }

  public clearErrors () {
    Object.values(this.inputFields).forEach((fieldValue: InputField) => {
      fieldValue.errors = []
    })
  }

  public validate () {
    let valid = true
    Object.values(this.inputFields).forEach((fieldValue: InputField) => {
      const field = (this.$refs[fieldValue.name] as FormInputInstance)
      if (Array.isArray(field)) {
        valid = field.every((inputField: FormInputInstance) => inputField.validate()) && valid
      } else {
        valid = field.validate() && valid
      }
    })
    return valid
  }

  public back () {
    this.$router.push({ name: 'subscriptionActions' })
  }

  public goToSuspend () {
    this.$router.push({ name: 'subscriptionSuspend' })
  }
}
